import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import { FilterType, filterTypesJson } from "../../utils/filterType";
import Card from "./Card";
import Filter from "./Filter";
import FilterIssue from "./FilterIssue";
import { RootState } from "../../store/slices/rootSlice";

const Grantee = () => {
  const [searchParams] = useSearchParams();
  const [grantees, setGrantees] = useState<any[]>([]);
  const [type, setType] = useState<FilterType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.locale)
  
  useEffect(() => {
    setGrantees([]);
    const granteeSearchParams = new URLSearchParams();

    const queryType = searchParams.get('type');
    if (queryType) {
      const typeExist = filterTypesJson.find(i => i.value === queryType)
      if (typeExist) {
        setType({ ...typeExist, label: `Sesuai ${typeExist.label}` })
      }

      const queryFilter = searchParams.get('filter');
      if (queryFilter) {
        granteeSearchParams.append(`${queryType}`, queryFilter);
      }
    }

    const queryProvince = searchParams.get('province');
    if (queryProvince) {
      granteeSearchParams.append('province', queryProvince.split(',').filter(i => i !== '0').toString());
    }

    const queryKeywords = searchParams.get('query')
    if (queryKeywords) {
      granteeSearchParams.append('query', queryKeywords);
    }

    const queryIssue = searchParams.get('issue')
    if (queryIssue) {
      granteeSearchParams.append('issue', queryIssue);
    }

    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/v1/grantee/search?${granteeSearchParams.toString()}&lang=${locale.toUpperCase()}`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result?.grantees?.length > 0) {
            setGrantees(result.grantees);
          }
        },
        (error) => {
          console.error(error)
        }
      )
      .finally(() => {
        setLoading(false);
      })
  }, [searchParams]);

  const Items = () => (
    <Grid>
      {grantees.length > 0 && grantees.map((org, i) => {
        return <Card 
          key={i} 
          slug={org.slug} 
          title={org.name} 
          area={org.region}
          image={org.image}
        />
      })}
    </Grid>
  )

  return (
    <Wrapper className="h-100">
      <Heading>
        <Header title={t('IMFC GRANTEES PROFILE SUMMARY')} back={true} />
      </Heading>
      <Content>
        <Filter />
        
        {type?.value === 'issue' && (
          <>
            <Row>
              <FilterIssue />
              <ItemWrapper>
                {loading && (
                  <Fullscreen>
                    <Spinner />
                  </Fullscreen>
                )}
                {!loading && <Items />}
              </ItemWrapper>
            </Row>
          </>
        )}

        {type?.value !== 'issue' && (
          <>
            {!loading && type?.value !== 'issue' && <Items />}
            {loading && (
              <Fullscreen>
                <Spinner />
              </Fullscreen>
            )}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
`;

const Heading = styled.div`
  padding-bottom: 4rem
`;

const Content = styled.div`
  flex-grow: 2;
  background-color: #fff;
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, max-content));
  grid-gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
  width: 100%;
`;

const Row = styled.div`
  :before,
  :after
  {
    display: table;
    content: " ";
  }

  :after {
    clear: both;
  }
`;

const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemWrapper = styled.div`
  float: left;
  width: 100%;

  @media (min-width: 992px) {
    width: 75%;
  };
`;

export default Grantee;
