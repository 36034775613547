import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import UnexpectedError from './errors/UnexpectedError';
import './locales/i18n';
import store, { persistor } from './store';
import App from './App';
import Home from './features/Home';
import Grantee from './features/Grantee';
import GranteeDetail from './features/Grantee/Detail';
import Donor from './features/Donor';
import DonorDetail from './features/Donor/Detail';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <UnexpectedError />,
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'grantees',
        element: <Grantee />,
      },
      {
        path: 'grantees/:slug',
        element: <GranteeDetail />,
      },
      {
        path: 'donors',
        element: <Donor />,
      },
      {
        path: 'donors/:slug',
        element: <DonorDetail />,
      },
      {
        path: 'about',
        element: <DonorDetail about={true} />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
