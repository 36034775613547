import styled from 'styled-components';

interface ContactProps {
  image: string
  name: string
  email: string
}

function Contact(props: ContactProps) {
  return (
    <Wrapper>
      <Img src={props.image} alt={props.name} />
      <Details>
        <div>{props.name}</div>
        <div><a href={`mailto:${props.email}`}>{props.email}</a></div>
      </Details>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 15px;
  }
`;

const Details = styled.div`
  font-size: 13px;
  font-weight: 500;
  colors: #979797;
  margin-left: 20px;
  word-break: break-all;
`

const Img = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 60px;
  height: 60px;
`;

export default Contact;