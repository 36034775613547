import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Select, { StylesConfig } from 'react-select';

import Button from "../../components/Button";
import { FilterType, filterTypesJson } from "../../utils/filterType";
import { useTranslation } from "react-i18next";


const Filter = () => {
  const [type, setType] = useState<FilterType | null>(filterTypesJson[0]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [keywords, setKeywords] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const typeExist = filterTypesJson.find(i => i.value === 'donor')
    if (typeExist) {
      setType({ ...typeExist, label: typeExist.label })
    }
  }, [])

  const handleFilter = () => {
    const urlSearchParams : URLSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set('query', keywords);
    setSearchParams(urlSearchParams);
  }

  return (
    <Wrapper>
      <Inner>
        <Label>{t('Select')} {t(type?.label as string)}</Label>
        <InputControl>
          <Select
            options={[]} 
            placeholder={`${t('Select')} Area`}
            styles={selectStyles}
            components={{
              IndicatorSeparator: () => null
            }}
            isDisabled
          />
        </InputControl>
        <InputControl>
          <Input
            type="text" 
            name="keywords" 
            onChange={(e) => setKeywords(e.target.value)} placeholder={`${t('Keywords')}`} />
        </InputControl>
        <SearchButton variant="grantee" onClick={handleFilter}>
          <img src="/search.png" width={14} alt="" style={{ verticalAlign: 'baseline' }} />
          {`  ${t('Search')}`}
        </SearchButton>
      </Inner>
    </Wrapper>
  );
};

const selectStyles: StylesConfig<any> = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: '#fff',
    borderColor: 'transparent',
    borderBottomColor: '#EBEBEB',
    opacity: isDisabled ? 0.5 : 1
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#18406E'
        : isFocused
        ? '#18406E'
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? '#fff'
        : isFocused
        ? '#fff'
        : '#B7C1D0',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? '#fff'
            : '#B7C1D0'
          : undefined,
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: '#B7C1D0' }),
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center
`;

const Inner = styled.div`
  display: block;
  border: 1px solid #EFEFEF;
  background-color: #fff;
  padding: 30px 52px;
  border-radius: 12px;
  margin-top: -3rem;
  width: 100%;
  max-width: 950px;

  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  };

  :before,
  :after
   {
    display: table;
    content: " ";
  }
`;

const Col = styled.div`
  width: 25%;
  float: left;
  
  @media (max-width: 991px) {
    width: 50%;
    text-align: center;
  };

  @media (max-width: 575px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  };
`;

const SearchButton = styled(Button)`
  width: 25%;
  float: left;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 15px;
  };
`;

const Input = styled.input.attrs({
  type: 'text'
})`
  color: #979797;
  font-size: 16px;
  border: 0px solid #EBEBEB;
  border-bottom-width: 1px;
  line-height: 2.2;
  width: 100%;

  ::placeholder {
    color: #B7C1D0;
    opacity: 1;
  }
`;

const InputControl = styled(Col)`
  padding-right: 20px;
`;

const Label = styled.div`
  float: left;
  line-height: 2.2;
  width: 25%;
  
  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  };
`;

export default Filter;
