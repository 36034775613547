import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GeoJSON, MapContainer, Popup } from 'react-leaflet';
import { Feature, Geometry } from 'geojson';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { FilterType } from '../../utils/filterType';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/slices/rootSlice';

type Props = {
  type?: FilterType
}

type Content = {
  name: string
  image: string
}

const Map = ({ type }: Props) => {
  const mapRef = useRef<any>();
  const popup = useRef<any>();
  const [geojson, setGeojson] = useState<GeoJSON.FeatureCollection<any>>();
  const [clicked, setClicked] = useState<L.PopupEvent>();
  const [content, setContent] = useState<Content[]>([]);
  const [size, setSize] = useState([0, 0]);
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.locale)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/id.geo.json');
      const data = await response.json();
      setGeojson(data);
    }
    
    fetchData().catch(console.error);
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (clicked) {
      setContent([])

      fetch(`${process.env.REACT_APP_API_URL}/v1/map?type=${type?.value}&code=${clicked.target.feature.properties.id}&lang=${locale.toUpperCase()}`)
        .then(res => res.json())
        .then(
          (result) => {
            setContent(result?.contain)
          },
          (error) => {
            console.error(error)
          }
        )
    }
  }, [type, clicked])

  useEffect(() => {
    if (mapRef.current) {
      const percentage = (1440 - size[0]) * 0.0013;
      const zoom = percentage < 0 ? 5.4 : 5.4 - percentage;
      mapRef.current.setZoom(zoom);
    }
  }, [size])

  const onMouseOver = (e: L.LeafletMouseEvent) => {
    e.target.setStyle({ fillColor: type?.color });
    e.target.getTooltip().setLatLng(e.latlng).openTooltip(mapRef);
  }

  const onMouseOut = (e: L.LeafletMouseEvent) => {
    if (!e.target.isPopupOpen()) {
      e.target.setStyle({ fillColor: '#E0E0E0' });
    }
  }

  const onPopupOpen = (e: L.PopupEvent) => {
    setClicked(e);
    setTimeout(() => {
      e.target.setStyle({ fillColor: type?.color });
    }, 100);
  }

  const onPopupClose = (e: L.PopupEvent) => {
    setClicked(undefined);
    e.target.setStyle({ fillColor: '#E0E0E0' });
  }

  const onEachFeature = (feature: Feature<Geometry, any>, layer: L.Layer) => {
    const nameLocale = feature.properties[`name-${locale}`];
    layer.bindTooltip(nameLocale || feature.properties.name, {
      direction: 'top',
    });

    layer.bindPopup('', { autoClose: false }).openPopup();

    layer.on({
      mouseover: onMouseOver,
      mouseout: onMouseOut,
      popupopen: onPopupOpen,
      popupclose: onPopupClose
    });
  }

  return (
    <>
      <MapContainer
        center={[-2, 118.1]}
        zoom={5.40}
        zoomSnap={0.1}
        dragging={false}
        zoomControl={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        ref={mapRef}
        fadeAnimation={false}
        attributionControl={false}
      >
      { geojson && 
          <GeoJSON
            key={type?.color}
            data={geojson}
            style={{
              weight: 1.5,
              color: '#fff',
              fillColor: '#E0E0E0',
              fillOpacity: 1
            }}
            onEachFeature={onEachFeature}
          />
        }
        { clicked &&
          <Popup
            ref={popup}
            position={clicked.popup.getLatLng()}
            autoClose={false}
            keepInView={true}
            autoPan={true}
          >
            <div style={{ width: 140 }}>
              {content.map(({ name, image }, idx) => (
                <Img key={idx} src={image} alt={name} height={27} />
              ))}
              
              <div style={{ textAlign: 'center', marginTop: 5 }}>
                <LinkStyled
                  to={`grantees?type=${type?.value}&province=${clicked.target.feature.properties.id}`} 
                >
                  {t('View more')}
                </LinkStyled>
              </div>
            </div>
          </Popup>
        }
      </MapContainer>
    </>
  );
};

const Img = styled.img`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const LinkStyled = styled(Link)`
  color: #79CBE8 !important;
  text-decoration: none;
  font-size: 10px;
`;

export default memo(Map);
