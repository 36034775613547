import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface Props {
  text: string
}

function ReadMoreGrantee({ text }: Props) {
  const [isReadMoreGrantee, setIsReadMoreGrantee] = useState(true);
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsReadMoreGrantee(!isReadMoreGrantee);
  };

  if (!text || text.length <= 150) {
    return <Text>{text}</Text>
  }

  return (
    <Text>
      {isReadMoreGrantee ? text?.slice(0, 150) : text}
      <Button onClick={handleToggle}>
        {isReadMoreGrantee ? `...${t('View more')}` : ` ${t('View less')}`}
      </Button>
    </Text>
  )
}

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #979797;
  margin-bottom: 28px;
  margin-top: 5px;
`;

const Button = styled.span`
  color: #4196FB;
  cursor: pointer;
`;

export default ReadMoreGrantee;