import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface RootState {
  locale: 'id' | 'en'
}

const initialState: RootState = {
  locale: 'id',
}

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<RootState['locale']>) => {
      state.locale = action.payload
    }
  },
})

export const { setLocale } = rootSlice.actions

export default rootSlice.reducer