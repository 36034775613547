import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import Header from "../../components/Header";
import { RootState } from "../../store/slices/rootSlice";
import Card from "./Card";
import Filter from "./Filter";

const Donor = () => {
  const [searchParams] = useSearchParams();
  const [donors, setDonors] = useState<any[]>([]);
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.locale)
  
  useEffect(() => {
    setDonors([]);
    const donorSearchParams = new URLSearchParams();

    const queryType = searchParams.get('type');
    const queryFilter = searchParams.get('filter');
    if (queryType && queryFilter) {
      donorSearchParams.append(`${queryType}`, queryFilter);
    }

    const queryKeywords = searchParams.get('query')
    if (queryKeywords) {
      donorSearchParams.append('query', queryKeywords);
    }

    fetch(`${process.env.REACT_APP_API_URL}/v1/donor/search?${donorSearchParams.toString()}&lang=${locale.toUpperCase()}`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result?.donor?.length > 0) {
            setDonors(result.donor);
          }
        },
        (error) => {
          console.error(error)
        }
      )
  }, [searchParams]);

  return (
    <Wrapper className="h-100">
      <Heading>
        <Header title={t('IMFC GRANTEES PROFILE SUMMARY')} back={true} />
      </Heading>
      <Content>
        <Filter />
        <Grid>
          {donors?.length > 0 && donors.map((org, i) => {
            return <Card 
              key={i} 
              slug={org.slug} 
              title={org.name} 
              area={org.region}
              image={org.image} />
          })}
        </Grid>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
`;

const Heading = styled.div`
  padding-bottom: 4rem
`;

const Content = styled.div`
  flex-grow: 2;
  background-color: #fff;
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, max-content));
  grid-gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
`;

export default Donor;
