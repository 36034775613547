import { useState } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from '../../components/Button';
import Header from '../../components/Header';
import { FilterType, filterTypesJson } from '../../utils/filterType';
import Map from './Map';
import Region from './Region';


const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState<FilterType>(filterTypesJson[0]);

  const handleClick = (filterType: FilterType) => {
    if (filterType.value === 'about') {
      return navigate('/about');
    }

    setType(filterType);
  }

  return (
    <Wrapper className='h-100'>
      <Header title={t('IMFC GRANTEES PROFILE SUMMARY')} />
      <MapWrapper>
        <BtnGroup>
          {filterTypesJson.filter(i => i.value !== 'province').map(filterType => (
            <Button
              key={filterType.value}
              variant={filterType.value as ButtonProps['variant']}
              className={type.value === filterType.value ? 'active' : ''}
              style={{ marginBottom: 10 }}
              onClick={() => handleClick(filterType)}
              outline
            >
              {t(filterType.label)}
            </Button>
          ))}
        </BtnGroup>
        <MediaQuery maxWidth={767}>
          <div className='container'>
            <div className='my-1'>{t('Regions')}</div>
            <Region type={type} />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <Map type={type} />
        </MediaQuery>
      </MapWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MapWrapper = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: top;
  position: relative;
`

const BtnGroup = styled.div`
  display: flex;
  z-index: 1;

  /* mobile */
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 10px;
  }

  /* not mobile */
  @media (min-width: 768px) {
    flex-direction: column;
    position: absolute;
    bottom: 3rem;
    left: 3rem;
  }
`;



export default Home;
