import styled from 'styled-components';
import logo from '../../logo.svg';

function Logo() {
  return (
    <Wrapper>
      <img src={logo} height={48} alt="IMFC" />
      <Title>Indonesia Marine Funders Collaboration</Title>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  padding-left: 16px;
  margin-right: 50px;
  text-align: left;

  @media (min-width: 768px) {
    margin-right: 0;
    font-size: 24px;
    text-align: center;
  }
`;

export default Logo;