import styled from 'styled-components';

export interface TitleProps {
  title: string
  subtitle?: string
}

function Title(props: TitleProps) {
  return (
    <Wrapper>
      <H1>{props.title}</H1>
      <Description>{props.subtitle}</Description>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const H1 = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 24px;

  @media (min-width: 768px) {
    font-size: 42px;
  }
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: #6F6F6F;

  @media (min-width: 768px) {
    font-size: 25px;
  }
`;

export default Title;