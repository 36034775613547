import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import { RootState } from "../../store/slices/rootSlice";

interface Props {
  color?: string
  value?: number
  active?: boolean
}
 
interface Issue {
  id: number
  name: string
  image: string
  color: string
  percentage: number
}

const FilterIssue = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const [issues, setIssues] = useState<Issue[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const locale = useSelector((state: RootState) => state.locale)

  useEffect(() => {
    if (issues.length === 0) {
      fetch(`${process.env.REACT_APP_API_URL}/v1/issue/issue-count?lang=${locale.toUpperCase()}`)
        .then(res => res.json())
        .then(
          (result) => {
            if (result?.issues?.length > 0) {
              const max = result.MaxCount || 1;
              setIssues(result.issues.map((issue: any) => ({
                ...issue,
                percentage: (issue.count / max) * 100
              })));

              const queryIssue = searchParams.get('issue');
              if (queryIssue) {
                setSelected([Number(queryIssue)]);
              }
            }
          },
          (error) => {
            console.error(error)
          }
        )
    }
  }, []);

  const isActive = useCallback((id: number) => {
    if (selected.includes(id)) {
      return true;
    }

    return false;
  }, [selected])

  const handleClick = (id: number) => {
    let updatedId = id.toString();

    if (selected.includes(id)) {
      setSelected(selected.filter(i => i !== id));
      updatedId = '';
    } else {
      setSelected([id]);
    }

    const urlSearchParams : URLSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set('issue', updatedId);
    setSearchParams(urlSearchParams);
  };

  

  return (
    <Wrapper>
      {issues.length > 0 && issues.map((issue, key) => (
        <Item key={key} color={issue.color} active={isActive(issue.id)} onClick={() => handleClick(issue.id)}>
          <Image src={issue.image} active={isActive(issue.id)} />
          <Percentage value={issue.percentage} color={issue.color} active={isActive(issue.id)}>
            <span>{issue.name}</span>
          </Percentage>
          <Name active={isActive(issue.id)}>{issue.name}</Name>
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 3rem;
  overflow-x: auto;
  white-space: nowrap;

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    float: left;
    width: 25%;
    padding-right: 30px;
    display: block;
    margin-bottom: 30px;
  };
`;

const Image = styled.img<Props>`
  @media (max-width: 991px) {
    ${props => props.active && 'filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(123deg) brightness(103%) contrast(101%)'}
  };
`;

const Name = styled.div<Props>`
  margin-top: 7px;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.active ? '#fff' : '#B7C1D0'};

  @media (min-width: 992px) {
    display: none;
  };
`;

const Item = styled.div<Props>`
  align-items: center;
  margin-bottom: 15px;
  padding: 0;
  background-color: transparent;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 991px) {
    display: inline-block;
    padding: 1rem 1rem 0.5rem 1rem;
    background-color: ${props => props.active ? props.color : 'transparent'};
    border: 1px solid ${props => props.active ? props.color : '#efefef'};
    color: #B7C1D0;
    margin-right: 1rem;
    border-radius: 10px;
    width: 82px;
    text-align: center;
  };

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
  };
`;

const Percentage = styled.div<Props>`
  position: relative;
  margin-left: 30px;
  background-color: #aba8a8;
  width: 100%;
  height: 50px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  opacity: ${props => props.active ? 1 : 0.4};
  display: none;

  :after {
    content: "";
    position: absolute;
    background-color: ${props => props.color};
    width: ${props => props.value}%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    top: 0;
    left: 0;
    bottom: 0;
  }

  :hover {
    cursor: pointer;
    opacity: 1;

    span {
      display: block;
    }
  }

  span {
    position: relative;
    z-index: 1;
    padding-left: 30px;
    color: #fff;
    line-height: 50px;
    display: ${props => props.active ? 'block' : 'none'};
  }

  @media (min-width: 992px) {
    display: block;
  };
`

export default FilterIssue;
