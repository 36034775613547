import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux'
import './App.css';
import { setLocale, RootState } from './store/slices/rootSlice'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const locale = useSelector((state: RootState) => state.locale)

  const changeLanguage = async (lng: RootState['locale']) => {
    await dispatch(setLocale(lng));
    i18n.changeLanguage(lng);
    window.location.reload();
  }

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [])

  return (
    <Container>
      <Select
        value={locale}
        onChange={(e) => changeLanguage(e.target.value as RootState['locale'])}>
        <option value="id">&#x1F1F2;&#x1F1E8; ID</option>
        <option value="en">&#x1F1F1;&#x1F1F7; EN</option>
      </Select>
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
    </Container>
  );
}

const Select = styled.select`
  position: absolute;
  right: 15px;
  top: 35px;
  margin: 1rem;
`

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
`
export default App;
