import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationID from './id-ID.json';
import translationEN from './en-US.json';

const translationsJson = {
  id: {
    translation: translationID,
  },
  en: {
    translation: translationEN,
  }
};

const i18n = i18next
  .use(initReactI18next)
  .init({
    resources: translationsJson,
    lng: 'id',
    fallbackLng: 'id',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;