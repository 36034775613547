import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";

interface Props {
  image: string
  slug: string
  title: string
  area: string
}

const Card = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Heading>{ props.title }</Heading>
      <Content>
        <img src={props.image} alt={props.title} width={65} />
        <Row>
          {props.area && (
            <>
              <Title>Area</Title>
              <Text>{props.area}</Text>
            </>
          )}
          
        </Row>
      </Content>
      <Overlay>
        <Link to={`/grantees/${props.slug}`}>
          <Button variant="grantee">{t('View more')}</Button>
        </Link>
      </Overlay>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  width: fit-content;
  width: 340px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.45);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  display: none;

  ${Wrapper}:hover & {
    display: flex !important;
  }
`;

const Heading = styled.div`
  padding-bottom: 12px;
  margin-bottom: 20px;
  color: #979797;
  font-size: 14px;
  border-bottom: 1px solid #E2E4E8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

const Text = styled.span`
  display: -webkit-box;
  color: #B7C1D0;
  font-size: 13px;
  max-width: 150px;
  max-height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled(Text)`
  font-weight: 500;
  color: #979797;
  text-align: right;
  padding-right: 0.5rem;
  min-width: 40px;
`;

export default Card;
