import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import Overview from "../../components/Overview";
import ReadMoreGrantee from "../../components/ReadMoreGrantee";
import Spinner from "../../components/Spinner";
import { RootState } from "../../store/slices/rootSlice";

const GranteeDetail = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<any>(null);
  const { slug } = useParams();
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.locale)

  useEffect(() => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/v1/grantee/${slug}?lang=${locale.toUpperCase()}`)
      .then(res => res.json())
      .then(
        (result) => {
          setDetails(result);
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => setLoading(false))
  }, [slug]);

  return (
    <Wrapper>
      {loading && (
        <Fullscreen>
          <Spinner />
        </Fullscreen>
      )}
      {!loading && (
        <>
          <Header>
            <Breadcrumb text={t('Grantees') as string} />
            <Title>{details.name}</Title>
          </Header>
          <Body>
            <Overview
              image={details.image}
              name={details.name}
              url={details.URL}
              issues={details.issues}
              contacts={details.contacts}
            />
            <Content>
              <ReadMoreGrantee text={details.description} />
              {details.issue_description && (
                <>
                  <Subtitle>{t('PRIORITY ISSUE')}</Subtitle>
                  <Text>{details.issue_description}</Text>
                </>
              )}
              {details.regions?.length > 0 && (
                <>
                  <Subtitle>{t('WORK AREA')}</Subtitle>
                  <Text>{details.regions?.map(({ name }: { name: string }) => name).join(', ')}</Text>
                </>
              )}
              {/* Hide */}
              {false && (
                <>
                  <Subtitle>{t('DONOR')}</Subtitle>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 30, marginTop: 12, flexWrap: 'wrap' }}>
                    {details.donors.map(({ slug, name, image }: any) => (
                      <Link to={`/donors/${slug}`} key={slug}>
                        <CardItem>
                          <img src={image} height={55} alt={name} />
                        </CardItem>
                      </Link>
                    ))}
                  </div>
                </>
              )}
              {details.documents?.length > 0 && (
                <>
                  <Subtitle>{t('DOCUMENTS')}</Subtitle>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 28, marginTop: 12, flexWrap: 'wrap' }}>
                    {details.documents.map(({ url, name }: any) => (
                      <a href={url} target="_blank" rel="noreferrer">
                        <CardItem>
                          {name}
                        </CardItem>
                      </a>
                    ))}
                  </div>
                </>
              )}
            </Content>
            <SimilarIssues>
              <div style={{ color: '#979797', fontWeight: 500, fontSize: '20px', marginBottom: 20 }}>{t('Similar Issue')}</div>

              {details.similar && (
                <>
                  {details.similar.map((similar: any) => {
                    return (
                      <div key={similar.slug} style={{ justifyContent: 'center', alignItems: 'center', padding: '20px', border: '1px solid #EFEFEF', borderRadius: '10px', marginBottom: 20 }}>
                        <div style={{ fontSize: '14px', fontWeight: '500', color: '#979797', paddingBottom: 8, marginBottom: 20, borderBottom: '1px solid #E2E4E8' }}>
                          {similar.name}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
                          <CardItem>
                            <img src={similar.image} width={65} alt={similar.name} />
                          </CardItem>
                          <Link to={`/grantees/${similar.slug}`}>
                            <Button variant="grantee" style={{ paddingLeft: 15, paddingRight: 15 }}>{t('View more')}</Button>
                          </Link>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </SimilarIssues>
          </Body>
        </>
      )}
    </Wrapper>
  );
};

const Body = styled.div`
  background-color: #fff;
  :before,
  :after
   {
    display: table;
    content: " ";
  }

  :after {
    clear: both;
  }
`;

const Content = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 820px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 58.33333333%;
  };
  
  @media (min-width: 992px) {
    width: 50%;
  };
`;

const Wrapper = styled.div`
  background-color: #fff;
  padding-left: 70px;
  padding-right: 70px;
  min-height: 100%;
  white-space: pre-line;
  @media (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  };
`;

const Header = styled.div`
  border-bottom: 1px solid #E2E4E8;
  padding-top: 50px;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 35px;
  color: #979797;
`;

const CardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
`;

const Subtitle = styled.b`
  font-size: 20px;
  color: #979797
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #979797;
  margin-bottom: 28px;
  margin-top: 5px;
`;

const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SimilarIssues = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 992px) {
    width: 25%;
    padding-left: 30px;
    border-left: 1px solid #E2E4E8;
  };
`;

export default GranteeDetail;

