import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface Props {
  text: string
}

function ReadMore({ text }: Props) {
  const [isReadMore, setIsReadMore] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsReadMore(!isReadMore);
  };

  if (!text || text.length <= 150) {
    return <Text>{text}</Text>
  }

  return (
    <Text>
      {isReadMore ? text?.slice(0, 150) : text}
      <Button onClick={handleToggle}>
        {isReadMore ? `...${t('View more')}` : ` ${t('View less')}`}
      </Button>
    </Text>
  )
}

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #979797;
  margin-bottom: 28px;
  margin-top: 5px;
`;

const Button = styled.span`
  color: #4196FB;
  cursor: pointer;
`;

export default ReadMore;