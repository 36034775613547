import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import Breadcrumb from "../../components/Breadcrumb";
import Overview from "../../components/Overview";
import ReadMore from "../../components/ReadMore";
import Spinner from "../../components/Spinner";
import { RootState } from "../../store/slices/rootSlice";

interface Props {
  about?: boolean
}

const DonorDetail = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<any>(null);
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.locale)
  let { slug } = useParams();

  if (props.about) {
    slug = 'Indonesia-Marine-Funders-Collaboration';
  }

  useEffect(() => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/v1/donor/${slug}?lang=${locale.toUpperCase()}`)
      .then(res => res.json())
      .then(
        (result) => {
          setDetails(result);
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => setLoading(false))
  }, [slug]);

  return (
    <Wrapper>
      {loading && (
        <Fullscreen>
          <Spinner />
        </Fullscreen>
      )}
      {!loading && (
        <>
          <Header>
            <Breadcrumb text="About" active />
            <Title>{details.name}</Title>
          </Header>
          <Body>
            <Overview
              image={details.image}
              name={details.name}
              url={details.url}
              issues={details.issue}
              regions={details.regions}
              // contact={{ name: details.contact_name, email: details.contact_email }}
            />
            <Content>
              <Subtitle>{t('OVERVIEW OF INDONESIA PORTOFOLIO')}</Subtitle>
              <ReadMore text={details.overview} />
              {/* {details.grantees?.length > 0 && (
                <>
                  <Subtitle>{t('GRANTEES')}</Subtitle>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 28, marginTop: 12, flexWrap: 'wrap' }}>
                    {details.grantees.map(({ slug, name, image }: any) => (
                      <Link to={`/grantees/${slug}`} key={slug}>
                        <CardItem>
                          <img src={image} height={55} alt={name} />
                        </CardItem>
                      </Link>
                    ))}
                  </div>
                </>
              )} */}
            </Content>
          </Body>
        </>
      )}
    </Wrapper>
  );
};

const Body = styled.div`
  background-color: #fff;
  :before,
  :after
   {
    display: table;
    content: " ";
  }

  :after {
    clear: both;
  }
`;

const Content = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 820px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 58.33333333%;
  };
  
  @media (min-width: 992px) {
    width: 75%;
  };
`;

const Wrapper = styled.div`
  background-color: #fff;
  padding-left: 70px;
  padding-right: 70px;
  min-height: 100%;
  white-space: pre-line;
  @media (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  };
`;

const Header = styled.div`
  border-bottom: 1px solid #E2E4E8;
  padding-top: 50px;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 35px;
  color: #979797;
`;

const CardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  padding: 5px 10px;
`;

const Subtitle = styled.b`
  font-size: 20px;
  color: #979797
`;

const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DonorDetail;

