import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { RootState } from '../../store/slices/rootSlice';
import { FilterType } from '../../utils/filterType';

type Props = {
  type?: FilterType
}

// const national = {
//   id: 0,
//   image: '/national.png',
//   name: 'Nasional'
// }

const Region = ({ type }: Props) => {
  const navigate = useNavigate();
  const [selectedRegion, setSelectedRegion] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true);
  const [regions, setRegions] = useState<any>([]);
  const locale = useSelector((state: RootState) => state.locale)

  useEffect(() => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/v1/map/island?lang=${locale.toUpperCase()}`)
      .then(res => res.json())
      .then(
        (result) => {
          setRegions([...result.islands]);
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => setLoading(false))
  }, []);

  const handleCollapse = (key: number) => {
    if (key === 0) {
      return navigate(`grantees?type=${type?.value}`)
    }

    if (key === selectedRegion) {
      return setSelectedRegion(null)
    }
  
    setSelectedRegion(key);
  }

  if (loading || regions.length === 0) {
    return null;
  }

  return (
    <>
      {regions?.map(({ id, contain, image, name }: any) => (
        <div key={id}>
          <RegionItem onClick={() => handleCollapse(id)}>
            <div style={{ marginRight: 20 }}>
              <img src={image} width={45} alt='Region' />
            </div>
            <div>
              {name}
            </div>
          </RegionItem>
          {contain?.length > 0 && (
            <Province hidden={selectedRegion !== id}>
              {contain.map(({code, name}: any) => (
                <LinkStyled
                  key={code}
                  to={`grantees?type=${type?.value}&province=${code}`} 
                >
                  {name}
                </LinkStyled>
              ))}
            </Province>
          )}
        </div>
      ))}
    </>
  );
};

const Province = styled.div<{hidden: boolean}>`
  display: ${props => props.hidden ? 'hidden' : 'block'};
`;

const RegionItem = styled.div`
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  height: 68px;

  :hover {
    cursor: pointer;
  }
`;

const LinkStyled = styled(Link)`
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #979797;
  border-radius: 10px;

  :hover {
    background-color: #18406E;
    color: #fff;
  }

  :last-child {
    margin-bottom: 15px;
  }
`;

export default Region;
