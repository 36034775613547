export interface FilterType {
  label: string;
  value: string;
  color?: string;
}

export const filterTypesJson: FilterType[] = [
  {
    "label": "Grantees",
    "value": "grantee",
    "color": "#79cbe8"
  },
  {
    "label": "Issues",
    "value": "issue",
    "color": "#18406e"
  },
  {
    "label": "About",
    "value": "about",
    "color": "#479fc1"
  },
]

