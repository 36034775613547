import styled from 'styled-components';

interface IssueProps {
  image: string
  name: string
}

function Issue(props: IssueProps) {
  return (
    <Wrapper>
      <img src={props.image} width={32} alt={props.name} />
      <Title>{props.name}</Title>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;
  margin: 5px 15px 5px 0;
`;

const Title = styled.span`
  font-size: 13px;
  colors: #979797;
  margin-left: 7px;
`

export default Issue;