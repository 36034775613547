import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './Logo';
import Title, { TitleProps } from './Title';

interface HeaderProps extends TitleProps {
  back?: boolean
}

const backBtnWidth = 35;

function Header(props: HeaderProps) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Heading>
        {props.back && (
          <Back onClick={() => navigate('/')}>
            <img src='/back.png' alt='Back' width={backBtnWidth} />
          </Back>
        )}
        
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Heading>
      <Title title={props.title} subtitle={props?.subtitle} />
    </Wrapper>
  );
}

const Back = styled.div`
  display: block;
  text-align: left;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: unset;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  color: #18406E;
  padding: 3rem 2rem 0 2rem;
`;

const LogoWrapper = styled.div`
  flex: 1;
  margin-left: -${backBtnWidth}
`;

export default Header;