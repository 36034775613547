import { useRouteError } from "react-router-dom";

type RouteError = {
  statusText?: string
  message?: string
}

function UnexpectedError() {
  const error = useRouteError() as RouteError;
  console.error(error);

  return (
    <h1>{error.statusText || error.message}</h1>
  );
}

export default UnexpectedError;
