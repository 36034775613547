import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface BreadcrumbProps {
  text?: string
  active?: boolean
}

function Breadcrumb(props: BreadcrumbProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(-1);
  }

  return (
    <Wrapper>
      <Img src='/arrow-back.png' alt='' onClick={handleBack} />
      <Home to={'/'}>{t('Home')}</Home> &gt;{' '}
      {props.active && props.text && (<Active>{t(props.text)}</Active>)}
      {!props.active && (
        <>
          <Back onClick={handleBack}>
            {props.text}
          </Back> &gt;{' '}
          <Active>{t('Detail')}</Active>
        </>
      )}
    </Wrapper>
  );
}

const Img = styled.img`
  margin-right: 20px;

  :hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
`;

const Home = styled(Link)`
  color: #979797;
  text-decoration: none;
`;

const Back = styled.span`
  color: #979797;
  
  :hover {
    cursor: pointer;
  }
`;

const Active = styled.span`
  color: #79CBE8;
`;

export default Breadcrumb;