import styled from 'styled-components';
import React, { Children } from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'grantee' | 'donor' | 'issue';
  outline?: boolean
}

function Button(props: ButtonProps) {
  return (
    <BaseButton {...props}>
      {Children.toArray(props.children)}
    </BaseButton>
  )
}

const BaseButton = styled.button<ButtonProps>`
  display: inline-block;
  box-sizing: border-box;
  padding: 0.4723em 1.777em;
  text-decoration: none;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: 1px solid #b7c1d0;
  font-size: 18px;
  background: #fdfdff;
  color: #b7c1d0;

  ${props => props.outline && `
    background-color: #fdfdff;
  `}

  ${props => (!props.outline || props.className === 'active') && props.variant === 'grantee' && `
    border-color: #79cbe8;
    background-color: #79cbe8;
    color: #fff;
  `}

  ${props => (!props.outline || props.className === 'active') && props.variant === 'donor' && `
    border-color: #479fc1;
    background-color: #479fc1;
    color: #fff;
  `}

  ${props => (!props.outline || props.className === 'active') && props.variant === 'issue' && `
    border-color: #18406e;
    background-color: #18406e;
    color: #fff;
  `}

  &:hover {
    opacity: 0.9;

    ${props => props.outline && props.variant === 'grantee' && `
      border-color: #79cbe8;
      background-color: #79cbe8;
      color: #fff;
    `}

    ${props => props.outline && props.variant === 'donor' && `
      border-color: #479fc1;
      background-color: #479fc1;
      color: #fff;
    `}


    ${props => props.outline && props.variant === 'issue' && `
      border-color: #18406e;
      background-color: #18406e;
      color: #fff;
    `}
  }
`;

export default Button;