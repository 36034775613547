import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Contact from '../Contact';
import Issue from '../Issue';

interface IssueProps {
  image: string
  name: string
}

interface ContactProps {
  image?: string
  name: string
  email: string
}

export interface TitleProps {
  image?: string
  name?: string
  url?: string
  regions?: string
  issues?: IssueProps[]
  contact?: ContactProps
  contacts?: ContactProps[]
}
  
function Overview(props: TitleProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {props.url && props.image && <a href={props.url} target="_blank" rel="noreferrer"><Img src={props.image} alt={props?.name} /></a>}
      {!props.url && props.image && <Img src={props.image} alt={props?.name} />}
      <Info>
        {(props.contact?.name || props.contact?.email) && (
          <>
            {props.contact?.name}{props.contact?.name && props.contact?.email && ' | '}{props.contact?.email && <a href={`mailto:${props.contact.email}`}>{props.contact.email}</a>}
          </>
        )}
        {props?.url && <div><a href={props.url} target="_blank" rel="noreferrer">{props.url}</a></div>}
      </Info>
      <Body>
        {props.issues && props.issues.length > 0 && (
          <>
            <Subtitle first>{t('Issue Areas')}</Subtitle>
            {props.issues?.map(({name, image}, idx) => 
              <Issue key={idx} name={name} image={image} />)}
          </>
        )}
        
        {props.regions && props.regions.length > 0 && (
          <>
            <Subtitle>{t('Regions')}</Subtitle>
            {props.regions}
          </>
        )}

        {props.contacts && props.contacts.length > 0 && (
          <>
            <Subtitle>{t('Contacts')}</Subtitle>
            {props.contacts && props.contacts?.map(({name, image, email}, idx) => 
              <Contact key={idx} name={name} image={image || ''} email={email} />)}
          </>
        )}
      </Body>
    </Wrapper>
  );
}

const Body = styled.div`
  background-color: #EFF3F9;
  padding: 30px;
`;

const Info = styled.div`
  font-size: 13px;
  text-align: center;
  padding: 30px;
`;

const Img = styled.img`
  width: 100%;
`;

const Subtitle = styled.div<{first?: boolean}>`
  font-size: 20px;
  font-weight: bold;
  margin-top: ${props => props.first ? 0 : '15px'};
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  color: #979797;
  width: 100%;
  float: left;
  margin-bottom: 30px;

  @media (min-width: 820px) {
    width: 41.66666667%;
  };
  
  @media (min-width: 992px) {
    width: 25%;
  };
`;

export default Overview;